$covers = null
$app = null
$details = null

showDetails = ($el) ->
    title = $el.attr 'title'
    $details.children('h2').text(title)
    $details.children('p').text('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam porta porta consectetur. Nullam mattis tincidunt velit a malesuada. In hac habitasse.')
    $details.removeClass 'inactive'
    setTimeout ->
        $details.addClass 'active'
    , 300

hideDetails = ->
    $details.removeClass 'active'
    setTimeout ->
        $details.addClass 'inactive'
    , 300

attachUIListeners = ->
    $covers.on 'click', (e) ->
        $el = $(this)
        oldOffset = $el.offset().top
        newOffset = (($app.height() - $el.height()) / 2)
        translateY = newOffset - oldOffset
        unless $el.hasClass 'active'
            $app.addClass 'no-scroll'
            $el.addClass 'active'
            $el.css
                'transform' : "rotateX(0) translateY(#{translateY}px)"
            $covers.not('.active').addClass('inactive')
            showDetails $el
        else
            $app.removeClass 'no-scroll'
            $el.removeClass 'active'
            $el.attr 'style', ''
            $covers.removeClass 'inactive'
            hideDetails()

$(document).ready ->
    $app = $('.app')
    $covers = $('.cover-flow__item')
    attachUIListeners()
    $details = $('.details')

